import React from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';
import { allPosts } from '../data/blog/posts';
import { formatDate } from '../utils/date';
import { ArrowLeft } from 'lucide-react';
import { SEO } from '../components/SEO';

export function BlogPost() {
  const { slug } = useParams();
  const post = allPosts.find(p => p.slug === slug);

  if (!post) {
    return <Navigate to="/blog" replace />;
  }

  const baseUrl = window.location.origin;
  const fullUrl = `${baseUrl}/blog/${slug}`;
  const fullImageUrl = `${baseUrl}${post.image}`;

  const renderContent = (content: string) => {
    const lines = content.split('\n');
    let inTable = false;
    let tableContent = [];

    return lines.map((line, index) => {
      // Handle HTML content
      if (line.startsWith('<') && line.endsWith('>')) {
        return (
          <div key={index} dangerouslySetInnerHTML={{ __html: line }} />
        );
      }

      // Handle table
      if (line.startsWith('|')) {
        if (!inTable) {
          inTable = true;
          tableContent = [];
        }
        tableContent.push(line);
        
        if (index === lines.length - 1 || !lines[index + 1].startsWith('|')) {
          inTable = false;
          const headerRow = tableContent[0];
          const headers = headerRow
            .split('|')
            .slice(1, -1)
            .map(header => header.trim());
          
          const rows = tableContent.slice(2); // Skip header and separator rows

          return (
            <div key={index} className="my-8 overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {headers.map((header, i) => (
                      <th
                        key={i}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {rows.map((row, i) => {
                    const cells = row.split('|').slice(1, -1);
                    return (
                      <tr key={i}>
                        {cells.map((cell, j) => {
                          if (j === 0) {
                            return (
                              <td key={j} className="px-6 py-4 whitespace-normal text-sm font-bold text-gray-900">
                                {cell.trim()}
                              </td>
                            );
                          }
                          return (
                            <td key={j} className="px-6 py-4 whitespace-normal text-sm text-gray-500">
                              {cell.trim()}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        }
        return null;
      }

      // Handle headings and other content
      if (line.startsWith('# ')) {
        return (
          <h1 key={index} className="text-4xl font-bold text-gray-900 mb-6">
            {line.replace('# ', '')}
          </h1>
        );
      } else if (line.startsWith('## ')) {
        return (
          <h2 key={index} className="text-3xl font-bold text-gray-900 mt-8 mb-4">
            {line.replace('## ', '')}
          </h2>
        );
      } else if (line.startsWith('- ')) {
        return (
          <li key={index} className="text-gray-700 mb-4 ml-6" dangerouslySetInnerHTML={{ 
            __html: line.replace('- ', '')
              .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
          }} />
        );
      } else if (line.trim() !== '') {
        return (
          <p key={index} className="text-gray-700 mb-4" dangerouslySetInnerHTML={{ 
            __html: line.replace(/class="text-indigo-600 hover:text-indigo-800/g, 'class="text-teal-600 hover:text-teal-800')
          }} />
        );
      }
      return null;
    }).filter(Boolean);
  };

  return (
    <div className="min-h-screen pt-24 pb-16 bg-gray-50">
      <SEO
        title={post.title}
        description={post.excerpt}
        image={fullImageUrl}
        url={fullUrl}
        datePublished={post.date}
        type="article"
      />
      <article className="max-w-3xl mx-auto px-4">
        <Link 
          to="/blog" 
          className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-8"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Blog
        </Link>
        
        <div className="mb-8">
          <time className="text-gray-500">{formatDate(post.date)}</time>
        </div>
        
        <div className="aspect-w-16 aspect-h-9 mb-8">
          <img
            src={post.image}
            alt={post.title}
            className="rounded-lg object-cover w-full h-[400px]"
          />
        </div>

        <div className="prose prose-lg max-w-none">
          {renderContent(post.content)}
        </div>
      </article>
    </div>
  );
}