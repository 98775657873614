import React from 'react';
import { Link } from 'react-router-dom';
import { DoyenBrand } from './brand/DoyenBrand';

export function PageFooter() {
  return (
    <footer className="bg-gray-50 border-t border-gray-200 py-8 mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center space-y-4">
          <div className="text-sm font-medium text-gray-500 flex flex-wrap justify-center gap-4">
            <div className="relative group">
              <Link to="/products/erp-migration" className="hover:text-gray-900">
                ERP Migration
              </Link>
            </div>
            <Link to="/products/security" className="hover:text-gray-900">
              Security
            </Link>
            <Link to="/about" className="hover:text-gray-900">
              About Us
            </Link>
            <Link to="/blog" className="hover:text-gray-900">
              Blog
            </Link>
            <a 
              href="https://app.dover.com/jobs/doyen-ai" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="hover:text-gray-900"
            >
              Careers
            </a>
            <Link to="/contact" className="hover:text-gray-900">
              Contact
            </Link>
          </div>
          <div className="text-sm text-gray-400">
            © {new Date().getFullYear()} <DoyenBrand />. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}