import React from 'react';
import { Database, ArrowRightLeft, RefreshCw, Shield } from 'lucide-react';
import { SEO } from '../../components/SEO';

export function ErpMigration() {
  return (
    <div className="min-h-screen">
      <SEO
        title="ERP Data Migration | Doyen AI"
        description="Seamlessly migrate your ERP data with AI-assisted automation"
        url={`${window.location.origin}/products/erp-migration`}
      />

      {/* Header Section - Dark */}
      <section className="bg-black text-white pt-32 pb-24">
        <div className="text-center">
          <h1 className="text-5xl font-bold mb-6">Accelerate ERP Migrations with AI</h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto px-4">
            ERP data migrations can take months. Our AI tools can accelerate migration time by 90% and work across the migration workflow - from extraction to mapping to reconciliation.
          </p>
        </div>
      </section>

      {/* Section 1 - Light */}
      <section className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="flex-1 space-y-6">
              <h2 className="text-3xl font-bold">Migrate Data 90% Faster</h2>
              <p className="text-lg text-gray-600">
                Automate hard tasks across extraction, transformation, and reconciliation — accelerating migrations by over 90%.
              </p>
            </div>
            <div className="flex-1">
              <img
                src="/website-images/erp_migration_data_flow_1020_700.png"
                alt="Data Automation Visualization"
                className="rounded-lg shadow-lg w-full"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Section 2 - Dark */}
      <section className="bg-black text-white py-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col md:flex-row-reverse items-center gap-12">
            <div className="flex-1 space-y-6">
              <h2 className="text-3xl font-bold">Overcome Obstacles with AI Co-Pilots</h2>
              <p className="text-lg text-gray-300">
                Our agents are tailored to help you with the hardest migrations tasks. They work with you every step of the way from extraction to data cleaning to reconciliation and ensure the higest quality migrations.
              </p>
            </div>
            <div className="flex-1">
              <img
                src="/website-images/erp_reconiliation_1020_700.png"
                alt="Data Mapping Visualization"
                className="rounded-lg shadow-lg w-full"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Section 3 - Light */}
      <section className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="flex-1 space-y-6">
              <h2 className="text-3xl font-bold">Flexible and Customizable</h2>
              <p className="text-lg text-gray-600">
                Doyen agents are designed to be flexible - handling complex migration requirements and enabling different workflows like incremental data loading or historical data loading.
              </p>
            </div>
            <div className="flex-1">
              <img
                src="/website-images/erp_migrations_1020_by_700.png"
                alt="Real-time Validation"
                className="rounded-lg shadow-lg w-full"
              />
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section - Dark */}
      <section className="bg-black text-white py-24">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {[
              {
                title: "Data Extraction",
                description: "Automatically generate data schemas from existing source systems.",
                icon: Database
              },
              {
                title: "Data Transformation",
                description: "Map and transform extracted data into your target format.",
                icon: ArrowRightLeft
              },
              {
                title: "Incremental Migration",
                description: "Support for full migrations and incremental updates.",
                icon: RefreshCw
              },
              {
                title: "Reconciliation",
                description: "Ensure data integrity between source and destination systems.",
                icon: Shield
              }
            ].map((step, index) => (
              <div key={index} className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
                <step.icon className="w-12 h-12 text-[#8000FF] mx-auto mb-4" />
                <h3 className="text-lg font-semibold mb-2 text-center">{step.title}</h3>
                <p className="text-gray-300 text-sm text-center">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section - Purple Gradient */}
      <section className="bg-gradient-to-br from-[#8000FF] to-[#6000CC] text-white py-24">
        <div className="max-w-3xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Transform Your ERP Migration?</h2>
          <p className="text-xl">
            Get in touch to learn how we can help accelerate your migration journey: {' '}
            <a href="mailto:info@doyen.com" className="hover:underline">
              info@doyen.com
            </a>
          </p>
        </div>
      </section>
    </div>
  );
}