type MetaTag = {
  name?: string;
  property?: string;
  content: string;
  'data-seo'?: string;
};

interface MetaData {
  title: string;
  description: string;
  image?: string;
  url: string;
  type?: 'website' | 'article';
  datePublished?: string;
  authorName?: string;
}

export function generateMetaTags(data: MetaData): MetaTag[] {
  const { title, description, image, url, type = 'website' } = data;
  
  if (!title || !description || !url) {
    throw new Error('Required meta tags are missing');
  }

  const siteName = 'Doyen AI';
  const tags: MetaTag[] = [
    { name: 'description', content: description, 'data-seo': 'description' },
    { property: 'og:title', content: title, 'data-seo': 'og:title' },
    { property: 'og:description', content: description, 'data-seo': 'og:description' },
    { property: 'og:url', content: url, 'data-seo': 'og:url' },
    { property: 'og:type', content: type, 'data-seo': 'og:type' },
    { property: 'og:site_name', content: siteName, 'data-seo': 'og:site_name' },
    { name: 'twitter:title', content: title, 'data-seo': 'twitter:title' },
    { name: 'twitter:description', content: description, 'data-seo': 'twitter:description' },
    { name: 'twitter:card', content: 'summary_large_image', 'data-seo': 'twitter:card' }
  ];

  if (image) {
    const absoluteImageUrl = image.startsWith('http') ? image : `${url}${image}`;
    tags.push(
      { property: 'og:image', content: absoluteImageUrl, 'data-seo': 'og:image' },
      { property: 'og:image:secure_url', content: absoluteImageUrl, 'data-seo': 'og:image:secure_url' },
      { property: 'og:image:type', content: 'image/webp', 'data-seo': 'og:image:type' },
      { property: 'og:image:width', content: '1200', 'data-seo': 'og:image:width' },
      { property: 'og:image:height', content: '630', 'data-seo': 'og:image:height' },
      { name: 'twitter:image', content: absoluteImageUrl, 'data-seo': 'twitter:image' }
    );
  }

  return tags;
}

export function generateStructuredData(data: MetaData) {
  const { title, description, image, url, datePublished, authorName, type = 'article' } = data;
  
  if (!title || !description || !url) {
    throw new Error('Required structured data fields are missing');
  }

  const absoluteImageUrl = image && (image.startsWith('http') ? image : `${url}${image}`);
  const baseStructuredData = {
    '@context': 'https://schema.org',
    '@type': type === 'article' ? 'BlogPosting' : 'WebPage',
    headline: title,
    description: description,
    url: url,
    publisher: {
      '@type': 'Organization',
      name: 'Doyen AI',
      logo: {
        '@type': 'ImageObject',
        url: `${url}/favicon/favicon.png`,
      },
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
  };

  if (type === 'article' && datePublished) {
    return {
      ...baseStructuredData,
      image: absoluteImageUrl,
      datePublished: datePublished,
      dateModified: datePublished,
      author: {
        '@type': 'Person',
        name: authorName || 'Doyen AI',
      },
    };
  }

  return baseStructuredData;
}