import React from 'react';
import { Helmet } from 'react-helmet-async';
import { generateMetaTags, generateStructuredData } from '../utils/seo';

interface SEOProps {
  title: string;
  description: string;
  image?: string;
  url: string;
  datePublished?: string;
  authorName?: string;
  type?: 'website' | 'article';
  noindex?: boolean;
}

export function SEO({ 
  title, 
  description, 
  image, 
  url, 
  datePublished,
  authorName,
  type = 'website',
  noindex = false
}: SEOProps) {
  const metaTags = generateMetaTags({ 
    title, 
    description, 
    image, 
    url, 
    type,
    datePublished,
    authorName 
  });

  const structuredData = generateStructuredData({ 
    title, 
    description, 
    image, 
    url, 
    type,
    datePublished,
    authorName 
  });

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      {noindex && <meta name="robots" content="noindex, nofollow" />}
      
      {metaTags.map((tag, index) => (
        <meta key={index} {...tag} />
      ))}

      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
}