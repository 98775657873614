import { BlogPost } from '../../../types/blog';

export const post: BlogPost = {
  slug: 'hello-world',
  title: 'Hello world!',
  excerpt: 'Welcome to the Doyen AI blog! Big things are coming.',
  image: '/blog-images/blog_post_hello_world.webp',
  date: '2024-09-16',
  content: `
# Hello world!

Welcome to the Doyen AI blog! Big things are coming. Sign up for our newsletter to stay connected.
  `
};