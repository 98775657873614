import React from 'react';
import { Linkedin, ChevronDown } from 'lucide-react';
import { founders } from '../data/team/founders';
import { GoogleLogo } from '../components/logos/GoogleLogo';
import { DoyenBrand } from '../components/brand/DoyenBrand';

export function About() {
  const scrollToTeam = () => {
    const teamSection = document.getElementById('team-section');
    teamSection?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen pt-16">
      <section className="relative min-h-screen flex flex-col justify-between bg-white px-4 pb-16">
        <div className="flex-grow flex items-center justify-center">
          <div className="max-w-3xl text-center">
            <h2 className="text-4xl font-bold text-gray-900 mb-8">
              Introducing <DoyenBrand />
            </h2>
            <p className="text-xl text-gray-700 mb-6">
              Tens of billions are spent annually on implementating and integrating enterprise software.
              The advent of LLMs and agents offer an opportunity to rethink implementations and dramatically decrease the time and resources needed to operationalize enterprise software.
            </p>
            <p className="text-xl text-gray-700 mb-6">
              Alex and Ryan first met during
              their PhDs in ML/AI at Caltech. Alex went on to found and sell an AI company, <a href="https://www.vidora.com/" target="_blank" rel="noopener noreferrer" className="text-teal-600 hover:text-teal-800">Vidora</a>. Ryan spent almost a decade at
              Google working on applied AI and agents. Throughout their career, they experienced first-hand the pain associated with enterprise implementations.
              They are building Doyen to fix the problem. 
            </p>

            <p className="text-xl text-gray-700">
              <DoyenBrand /> is venture-backed by leading VCs including <GoogleLogo />.
            </p>
          </div>
        </div>

        <div 
          className="flex justify-center mt-12 animate-bounce cursor-pointer"
          onClick={scrollToTeam}
        >
          <div className="flex flex-col items-center text-gray-500 hover:text-gray-700">
            <span className="text-sm mb-2">Meet the Team</span>
            <ChevronDown className="w-6 h-6" />
          </div>
        </div>
      </section>

      <section id="team-section" className="min-h-screen flex items-center justify-center bg-black px-4">
        <div className="max-w-3xl text-center">
          <h2 className="text-4xl font-bold text-white mb-12">Founding Team</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            {founders.map((founder) => (
              <div key={founder.name} className="flex flex-col items-center">
                <div className="w-48 h-48 rounded-full overflow-hidden border-2 border-white mb-6">
                  <img
                    src={founder.image}
                    alt={founder.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-2xl font-bold text-white mb-4">
                  {founder.name}
                </h3>
                <a
                  href={founder.linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white hover:text-gray-300 transition-colors"
                >
                  <Linkedin className="w-6 h-6" />
                </a>
              </div>
            ))}
          </div>

          <p className="text-xl text-gray-300 mt-16">
            We are a team of artificial intelligence experts and builders
            tackling one of the biggest opportunities in the enterprise.
          </p>
        </div>
      </section>
    </div>
  );
}