import React from 'react';

export function Home() {
  return (
    <div className="min-h-screen flex flex-col">
      <section className="min-h-screen flex items-center justify-center bg-black px-4">
        <div className="text-center max-w-2xl mx-auto -mt-16">
          <div className="animate-fade-in">
            <h1 className="text-6xl font-bold text-white mb-8">
              Doyen <span className="text-[#8000FF]">AI</span>
            </h1>
          </div>
          
          <div className="animate-fade-in animation-delay-1000">
            <p className="text-2xl text-gray-300">
              Implementing Enterprise Technology Shouldn't be Hard
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}