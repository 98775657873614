import { BlogPost } from '../../../types/blog';

export const post: BlogPost = {
  slug: 'ai-startups',
  title: 'What a difference AI makes: Building Startups in the Age of AI',
  excerpt: 'Reflections on how company building changed in the age of AI and results in more employee empowerment.',
  image: '/blog-images/ai_building.webp',
  date: '2025-01-10',
  content: `
# What a difference AI makes: Building Startups in the Age of AI

This is our second go around building an AI/ML startup.

The first  company, Vidora, we founded back in 2012 and sold a few years back. The tactics for building startups has gone through a phase transition since then. The release of ChatGPT back in late '22 being the seminal event. In our current company, founded about 6 months ago (<a href="https://www.doyen.com" target="_blank" rel="noopener noreferrer" class="text-teal-600 hover:text-teal-800">Doyen AI</a>), we find ourselves leveraging a spectrum of  AI tools. These tools are changing the tactics we use to build the business.

The TLDR?

Company-building today feels very different than in the prior pre-LLM age. The old adage "do more with less" is certainly more true now than in the past generation. But the change feels more profound. Early employees feel more empowered. More empowered to ideate, iterate, and launch more quickly.  Across the business. From marketing to engineering.

## Product & Engineering

- **Product Design Iterations**: Product design, in the past age, required time-consuming loops between the founders, designers, and engineers. We started to use <a href="https://0.dev" target="_blank" rel="noopener noreferrer" class="text-teal-600 hover:text-teal-800">v0</a> in late '24 and that loop was shattered. Using <a href="https://0.dev" target="_blank" rel="noopener noreferrer" class="text-teal-600 hover:text-teal-800">v0</a> we can quickly mock out a new UI or UX to understand how a product experience should look and feel. Short-circuiting the need for <a href="https://figma.com" target="_blank" rel="noopener noreferrer" class="text-teal-600 hover:text-teal-800">Figma</a> and other tools in the process. The result? Money saved, time saved, and a more dynamic and fun product development process. Anyone can ideate and iterate on product experiences.

- **Coding**: Tools like Cursor,  are all the rage as evidenced by their unbelievable growth trajectories. For us, we haven't found the stand along tools to be as impactful for us (maybe they will be in the future). That said, GitHub Co-Pilot with auto-complete is  increasing developer productivity and a regular tool we use.

## Marketing

- **Website Design**: Tools like <a href="https://bolt.new" target="_blank" rel="noopener noreferrer" class="text-teal-600 hover:text-teal-800">Bolt.new</a> (along with Netlify) allow non-technical folks to build functional websites and iterate on ideas without needing to outsource to a 3rd party or bothering engineering. Want to advertise a new Agent and Workflow, a marketer can quickly add a high-quality landing page.

- **Graphics**: Graphics were always challenging. Finding non-copyrighted graphics online or generating your own creatives was time consuming. AI-driven graphic tools enable us to build graphics using prompts. As a caveat, many of the images end up looking similar and often overly complex (vs. minimilastic) - at least when using ChatGPT. There may be better tools out there. As an aside, we've become rather good at spotting graphics generated by ChatGPT. The other drawback of current tools is not being able to iterate on an existing graphic. That said, AI-driven graphics creation is definitely a win - saving time and money.

- **Blog Posts**: Foundational models produce content that surpasses any junior marketer. Although the content generated is not particularly novel. For new ideas, ChatGPT Canvas ends up being a powerful ally to accelerate creating posts and content - likely a 2x speedup. Of course this post is also being written with the help of <a href="https://openai.com/index/introducing-canvas/"_blank" rel="noopener noreferrer" class="text-teal-600 hover:text-teal-800">OpenAI Canvas</a>!

## Legal

- **Contract Review**: When you are a small startup working the enterprise, legal documents are usually dictated by your partners - you'll sign their NDA to get the deal done. But one always needs to know what you are signing - just in case. Stepping through a verbose legal document filled with obscure legalese was not the most inspiring part of our day back at Vidora. This is another area where AI shines, enabling us to quickly summarize a document and interrogate different legal clauses.

## IT

- **Task Automation**: Google searches for how to setup email aliases, workspaces, or migrate to another domain were a time suck and often a rabbit hole. LLM prompts for the same information are often succinct and easy to follow. Time saved.

## Research

The rate of change in the AI world is astounding. New papers. New companies. New products. We've taken to allocating (or trying to allocate) time at least once a day scouring the latest news. AI is making it (somewhat) possible to keep up with the latest and greatest.

- **Daily Learning**: AI can act as companion while reading papers. Dumping the PDF into ChatGPT and interrogating ChatGPT on any concepts we are not familiar with. It's especially useful for more technical papers.

- **Content Summarization**: There's just too much content out there. AI can summarize that content. Pasting webpages, emails, or even articles into ChatGPT for summarization. can act as huge time saver.

- **(Soon) Daily Summaries**: In the past I used Google Alerts to stay up to date on competitors, partners, and interesting companies. Google sends a large number of blue links to my inbox every morning. I end up looking at small fraction of thosse. Very excited to leverage tools like <a href="https://help.openai.com/en/articles/10291617-scheduled-tasks-in-chatgpt/"_blank" rel="noopener noreferrer" class="text-teal-600 hover:text-teal-800">OpenAI Tasks</a> to summarize those links neatly each morning.

## Personal Productivity

- **Goal Tracking Tools**: Using tools like <a href="https://bolt.new" target="_blank" rel="noopener noreferrer" class="text-teal-600 hover:text-teal-800">Bolt.new</a> (and Netlify), we are able to build out customized goal-tracking tools to measure and discuss our performance.

- **Customized Software**: We will all be able to build customized and personalized software in the futue. Tools like v0 and Bolt are providing a glimpse into that future.

| | Before | After | The AI Delta |
| --- | --- | --- | --- |
| Product & Engineering | Lengthy design loops with multiple stakeholders. | Rapid UI/UX mockups using v0, enabling quicker feedback and iteration. | Significant reduction in time and costs. |
| Marketing | Outsourcing and slow cycles for website, graphics, and content creation. | AI-driven website creation, graphics generation, and blog post drafting. | Faster execution, lower costs. |
| Legal | Manual, time-consuming legal document review. | AI-driven document summaries and clause analysis. | Faster understanding, less effort. |
| IT | Manual, inefficient IT setup and troubleshooting. | Streamlined domain setups and IT tasks with LLM guidance. | Time saved on routine tasks. |
| Research | Manual reading and summarizing of articles and papers. | AI tools for paper analysis and quick content summarization. | Faster learning, better insights. |
| Personal Productivity | Limited customization of goal-tracking and productivity tools. | Customized, AI-powered goal-tracking tools for performance measurement. | Enhanced personal productivity. |

## Empowering Early Hires

More Empowerment, More Involvement: Early-stage companies provide unique opportunities for employees to learn and grow at a strategic level compared to larger, more established firms. AI tools enable everyone to contribute more meaningfully and take on broader responsibilities in company building. 

## Company Building in the Age of AI

Another benefit for using these tools? Eating our own dog food. At <a href="https://www.doyen.com" target="_blank" rel="noopener noreferrer" class="text-teal-600 hover:text-teal-800">Doyen AI</a>, we're building an AI company which leverages a lot of the same underlying transformer models as the tools above. By using these tools across the business we get a better understanding of what is and isn't possible. How to prompt. How to avoid hallucinations. How to work with the AI. And ultimately, how to maximize the value of AI engines.

Company building is still a monstrously hard endeavor. The fundamental goal of early-stage companies - finding PMF - hasn't changed. But dare I say it, these new AI tools have made the process more … fun (?).

What a difference AI makes.

<p><a href="https://www.youtube.com/watch?v=OmBxVfQTuvI" target="_blank" rel="noopener noreferrer" class="text-teal-600 hover:text-teal-800">Dinah Washington: What A Difference A Day Makes</a></p>
  `
};