import { BlogPost } from '../../../types/blog';

export const post: BlogPost = {
  slug: 'evolving-software',
  title: "Evolving Software with Goals and Guardrails",
  excerpt: "How advances in AI coding capabilities will transform software development through goals and guardrails.",
  image: "/blog-images/blog_post_guardrails.webp",
  date: "2024-11-19",
  content: `# Evolving Software with Goals and Guardrails

The nature of products is poised to change.

## Software Today

Most software today exists as a single code base, with each customer interacting with the same product powered by that singular code repo. For some software a singular product experience suffices. But when customization is needed, a single code base is limiting and can result in a complex UX (to accommodate customer customization) or a glut of services work around the product.

## Goals and Guardrails

AI offers an alternative. Automated coding enables software bespoke for each customer. Code is generated by setting "goals and guardrails" around what should be built based on the unique requirements of a customer. In <a href="https://podcasts.apple.com/us/podcast/bret-taylor-the-agent-era/id1154105909?i=1000668134453" target="_blank" rel="noopener noreferrer" class="text-indigo-600 hover:text-indigo-800 underline">this podcast</a>, Brett Taylor of <a href="https://sierra.ai/" target="_blank" rel="noopener noreferrer" class="text-indigo-600 hover:text-indigo-800 underline">Sierra AI</a> eloquently describes how "goals and guardrails" can be used to guide agentic behavior for customer support. Goals and guardrails could be a foundational framework for realizing AI value.

## Impact

What types of software will be most impacted? Software that today requires customization to generate value. One place to find opportunities in this shifting software landscape is in the IT software services sector. Enterprise software like ERPs, HRMS, SCM, and PLM often require bespoke customizations to unlock customer value which the large services companies like TCS, Accenture, Infosys provide today. Implementing and integrating enterprise software also requires additional layers of customization. We may soon see IT services and professional services increasingly leverage AI, goals and guardrails, and shift to the service-as-software model (<a href="https://www.sequoiacap.com/article/generative-ais-act-o1/" target="_blank" rel="noopener noreferrer" class="text-indigo-600 hover:text-indigo-800 underline">Sequoia has a great post</a> on which covers service-as-software among other changes AI is ushering into existence).

## New AI Software Tooling

There's no free lunch. Bespoke software repos will require new tools for AIs to maintain different software implementations. These tools will need to manage operations ranging from upgrades, security, documentation, and debugging across a large number of bespoke code repos.

The net result should be a huge boon for businesses as they realize increased efficiency: more customized software implementations to enable better operational efficiency, delivered faster and more cheaply.`
};