import { Founder } from '../../types/team';

export const founders: Founder[] = [
  {
    name: 'Alex Holub',
    role: 'Founder',
    image: '/team-images/alex_headshot.jpeg',
    linkedIn: 'https://www.linkedin.com/in/alexholub/',
  },
  {
    name: 'Ryan Gomes',
    role: 'Founder',
    image: '/team-images/ryan_headshot.jpg',
    linkedIn: 'https://www.linkedin.com/in/ryan-gomes-496a84a/',
  },
];
