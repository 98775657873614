import { BlogPost } from '../../../types/blog';

export const post: BlogPost = {
  slug: 'engineering-challenges',
  title: 'A New Era of Engineering Challenges',
  excerpt: 'Exploring the engineering challenges building products with customized coding and goals and guardrails.',
  image: '/blog-images/blog_post_eng_challenges.webp',
  date: '2024-12-01',
  content: `# A New Era of Engineering Challenges Ushered in by Customized Coding

Advances in AI are changing the nature of products. Products today can be tailored to each consumer or business in ways not possible in the pre-llm era - offering more flexibility and customization through automating code generation. (<a href="/blog/evolving-software" class="text-indigo-600 hover:text-indigo-800 underline">See this Doyen post for more on Goals and Guardrails for more custom code generation.</a>)

This will have two impacts in the short term.

The first impact will be that individual consumers will have the ability to build their own software - tailored to their specific needs. Personalized apps ranging from goal tracking to calendering will proliferate.

The second impact is on business software. Business software will no longer be one-size-fits-all. There may very well be standardized components which exist across all customers, but the peripheries of that software, how the software integrates with each business and accommodates unique busines workflows, will be customized to uniquely service a particular business.

## Rethinking Software and Software Development

These novel products require that we rethink not only how products are built, but the tools needed to maintain those products, and the ways users should interact with those products. Broadly two key functional areas deserve increased reflection –

1. Tools to Manage Customized Code Across Many Repos
2. Human-AI Workflows - New Interaction Paradigms to Explore

## Tools to Manage Customized Code Across Many Repos

To support code generated for each business we need to think about unique agentic frameworks which can manage and maintain code across a large number of repos in a largely automated fashion.

The task of builidng software doesn't stop after the code is written. The code should be maintained through it's lifecycle including updating code for product updates, changing inputs/outputs, changing compliance requirements, and changing operational requirements (for instance if more scale is required to support the business). As a practial example, how does one push a core component change across multiple repos and ensure it does not break any of the repos?

In a world where unique code exists for each customer, the process of maintaining the code becomes more arduous (and likely necessitates agentic frameworks).

## Human-AI Workflows - New Interaction Paradigms to Explore

As the products become more customized to unique business workflows, we will need to rethink how these products interact with humans. Humans will often need to validate intermediate workflow steps and final results. The chat interface may not be the best way to convey information to humans and enable them to interact. What types of visualizations are best for humans to review data and interact with an agent to manipulate that data? How should we visualize data transformations? 

## Software Development is Getting Fun (Again)

These are really exciting times to be an engineer or product manager. New ideas are needed. New tools are needed. Lots of green field to explore. We are entering a fascinating new world!`
};