import { BlogPost } from '../../../types/blog';

export const post: BlogPost = {
  slug: 'doyen-name',
  title: "Doyen: What's in a name?",
  excerpt: "The meaning behind the word 'Doyen'.",
  image: "/blog-images/blog_post_robo_monk.webp",
  date: "2024-09-25",
  content: `
# Doyen: What's in a name?

The etymology of "Doyen" begins with the Greek "deka," meaning ten. This evolved into the Latin "decanus," describing a leader of ten soldiers or monks, tasked with maintaining order and organizing duties. In medieval French, "decanus" transitioned to "deien," indicating a senior group member. By the 17th century, "deien" became "doyen" in French, and the term entered the English language. Today, "doyen" signifies someone who is the leading authority or the most esteemed person in a specific field.
  `
};