import React from 'react';

interface DoyenBrandProps {
  className?: string;
}

export function DoyenBrand({ className = '' }: DoyenBrandProps) {
  return (
    <span className={`font-bold ${className}`}>
      Doyen <span className="text-[#8000FF]">AI</span>
    </span>
  );
}