import React from 'react';
import { Link } from 'react-router-dom';
import { posts } from '../data/blog/posts';
import { formatDate } from '../utils/date';

export function Blog() {
  return (
    <div className="min-h-screen pt-24 pb-16 bg-gray-50 px-4">
      <div className="max-w-6xl mx-auto">
        <div className="grid gap-8">
          {posts.map((post) => (
            <Link 
              key={post.slug}
              to={`/blog/${post.slug}`}
              className="block bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-200 hover:shadow-xl hover:-translate-y-1"
            >
              <div className="grid md:grid-cols-[490px,1fr]">
                <div className="relative h-64 md:h-full">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
                <div className="p-8">
                  <time className="text-sm text-gray-500 mb-2 block">
                    {formatDate(post.date)}
                  </time>
                  <h2 className="text-2xl font-bold text-gray-900 mb-4 group-hover:text-gray-700">
                    {post.title}
                  </h2>
                  <p className="text-gray-600 mb-6 line-clamp-3">{post.excerpt}</p>
                  <span className="inline-flex items-center text-sm font-semibold text-gray-900 hover:text-gray-700">
                    Read more
                    <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}