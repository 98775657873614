import React from 'react';
import { Mail } from 'lucide-react';
import { SEO } from '../components/SEO';
import { DoyenBrand } from '../components/brand/DoyenBrand';

export function Contact() {
  return (
    <div className="min-h-screen pt-24 pb-16 bg-gray-50">
      <SEO
        title="Contact Us | Doyen AI"
        description="Get in touch with Doyen AI for general inquiries and career opportunities."
        url={`${window.location.origin}/contact`}
      />
      <div className="max-w-3xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">
          Contact <DoyenBrand />
        </h1>
        
        <div className="space-y-8">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">General Information</h2>
            <a 
              href="mailto:info@doyen.com"
              className="inline-flex items-center text-gray-600 hover:text-gray-900"
            >
              <Mail className="w-5 h-5 mr-2" />
              info@doyen.com
            </a>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Career Opportunities</h2>
            <div className="space-y-4">
              <a 
                href="mailto:careers@doyen.com"
                className="inline-flex items-center text-gray-600 hover:text-gray-900"
              >
                <Mail className="w-5 h-5 mr-2" />
                careers@doyen.com
              </a>
              <p className="text-gray-600 mt-4">
                We're always looking for amazing individuals to join our team. Check out our {' '}
                <a 
                  href="https://app.dover.com/jobs/doyen-ai"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-teal-600 hover:text-teal-800"
                >
                  current job openings
                </a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}