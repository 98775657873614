import { BlogPost } from '../../types/blog';
import { post as engineeringChallenges } from './posts/engineering-challenges';
import { post as integratingSoftware } from './posts/integrating-software';
import { post as evolvingSoftware } from './posts/evolving-software';
import { post as doyenName } from './posts/doyen-name';
import { post as helloWorld } from './posts/hello-world';
import { post as aiStartups } from './posts/ai-startups';

// Archive posts that should be accessible via URL but not shown in the main listing
export const archivedPosts: BlogPost[] = [
  engineeringChallenges,
  integratingSoftware,
  evolvingSoftware
];

// Active posts shown in the main blog listing
export const posts: BlogPost[] = [
  aiStartups,
  doyenName,
  helloWorld
];

// Combined posts for route handling
export const allPosts: BlogPost[] = [...posts, ...archivedPosts];